<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <custom-snackbar
        ref="snackbar"
      />
      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-col class="col-12 col-md-1 my-0 py-0 pr-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                class="btn-block v-btn--height-fix"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
             <v-list>
            <v-list-item @click="exportPdf">
              <v-list-item-title>Exportar PDF</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportExcel">
              <v-list-item-title>Exportar Excel</v-list-item-title>
            </v-list-item>
          </v-list>
          </v-menu>
        </v-col>
      </v-toolbar>
      <v-row class="mt-3">
        <v-col class="py-0 mb-2 col-12 col-md-3">
          <EmpresasSelect v-model="empresa_id" :rules="empresaRule" @change="fieldsSelected"></EmpresasSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-lg-2 col-md-3">
          <ProducoesSelect v-model="producao_id" :empresa="empresa_id" multiple return-object mostra-todos-label :rules="producaoRule" @change="fieldsSelected"></ProducoesSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-lg-3 col-md-3">
          <ProdutoSelect v-model="produto_id" :empresa="empresa_id" :disabled="!empresa_id" multiple insumos @change="fieldsSelected"></ProdutoSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-2">
          <v-select
            v-model="agrupar_por"
            :items="agruparOptions"
            outlined
            dense
            item-text="nome"
            item-value="valor"
            label="Agrupar por"
            @change="changeTitle"
            @input="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-1">
          <v-text-field
            v-model.number="ano"
            outlined
            dense
            label="Ano"
            @keydown="$stringFormat.validarNumero"
            :maxlength = "4"
            :rules="anoRule"
            @change="fieldsSelected"
          />
        </v-col>
        <v-col class="col-12 col-xs-12 col-sm-12 col-lg-1 py-0">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              v-bind="attrs"
              v-on="on"
              color="info"
              class="v-btn--height-fix"
              item-text="nome"
              item-value="id"
              @click="loadData"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            dense
            disable-pagination
            :class="classMonthStriped"
            :headers="headers"
            :items="data"
            :items-per-page="-1"
            :hide-default-footer="true"
            :group-by="agrupar_por"
            :loading="loading"
            :loading-text="$dataTableConfig.getLoadText()"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.mes1`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes1)
              }}</span>
            </template>

            <template v-slot:[`item.mes2`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes2)
              }}</span>
            </template>

            <template v-slot:[`item.mes3`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes3)
              }}</span>
            </template>

            <template v-slot:[`item.mes4`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes4)
              }}</span>
            </template>

            <template v-slot:[`item.mes5`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes5)
              }}</span>
            </template>

            <template v-slot:[`item.mes6`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes6)
              }}</span>
            </template>

            <template v-slot:[`item.mes7`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes7)
              }}</span>
            </template>

            <template v-slot:[`item.mes8`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes8)
              }}</span>
            </template>

            <template v-slot:[`item.mes9`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes9)
              }}</span>
            </template>

            <template v-slot:[`item.mes10`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes10)
              }}</span>
            </template>

            <template v-slot:[`item.mes11`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes11)
              }}</span>
            </template>

            <template v-slot:[`item.mes12`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.mes12)
              }}</span>
            </template>

            <template v-slot:[`item.total`]="{ item }">
              <span class="num_margin">{{
                $stringFormat.formatNumber(item.total)
              }}</span>
            </template>

            <template v-slot:[`group.header`]="{ group }">
              <td
                colspan="100%"
                class="py-1 td_group"
              >
                <b>- {{ title_group }} {{ group }}</b>
              </td>
            </template>

            <template v-slot:no-data>
              <v-spacer />
              <div v-if="dataError">
                <v-spacer />

                <div>
                  <v-row class="justify-center mt-1">
                    <v-col class="col-lg-6">
                      <custom-alert
                        text="Não foi possível obter essas informações"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>

              <div class="text-center" v-if="firstLoading">
                <h6 class="my-5">Selecione uma Empresa.</h6>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<style scoped>
.td_group{
   color: #395764 !important;
   background-color: #e0e0e0 !important;
}
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      dataError: '',

      snackText: '',
      search: '',
      loading: false,
      firstLoading: true,
      data: [],
      ano: 0,
      showData: false,
      pendingSearch: false,
      empresaRule: [(v) => !!v || 'Selecione a Empresa'],
      producaoRule: [(v) => !!v || 'Selecione a Produção'],
      produtoRule: [(v) => !!v || 'Selecione o Produto'],
      anoRule: [(v) => !!v || 'Informe o Ano'],
      agruparOptions: [
        { nome: 'Produção', valor: 'producao' },
        { nome: 'Produto', valor: 'produto' },
      ],

      empresa_id: '',
      producao_id: '',
      produto_id: '',
      agrupar_por: 'producao',
      title_group: 'Produção',
    }
  },

  computed: {
    classMonthStriped () {
      return 'striped--utilizacao-anual--mes-' + ((new Date()).getMonth() + 1)
    },
    headers () {
      return [
        { text: 'Empresa ID', value: 'empresa_id', align: ' d-none' },
        { text: 'Produção ID', value: 'producao_id', align: ' d-none' },
        { text: 'Produto ID', value: 'produto_id', align: ' d-none' },
        {
          text: this.agrupar_por === 'producao' ? 'Produção' : 'Produto',
          value: this.agrupar_por === 'producao' ? 'produto' : 'grupo',
          groupable: false,
          class: 'custom-table--header',
        },
        { text: 'JAN', value: 'mes1', align: 'right', class: 'custom-table--header' },
        { text: 'FEV', value: 'mes2', align: 'right', class: 'custom-table--header' },
        { text: 'MAR', value: 'mes3', align: 'right', class: 'custom-table--header' },
        { text: 'ABR', value: 'mes4', align: 'right', class: 'custom-table--header' },
        { text: 'MAI', value: 'mes5', align: 'right', class: 'custom-table--header' },
        { text: 'JUN', value: 'mes6', align: 'right', class: 'custom-table--header' },
        { text: 'JUL', value: 'mes7', align: 'right', class: 'custom-table--header' },
        { text: 'AGO', value: 'mes8', align: 'right', class: 'custom-table--header' },
        { text: 'SET', value: 'mes9', align: 'right', class: 'custom-table--header' },
        { text: 'OUT', value: 'mes10', align: 'right', class: 'custom-table--header' },
        { text: 'NOV', value: 'mes11', align: 'right', class: 'custom-table--header' },
        { text: 'DEZ', value: 'mes12', align: 'right', class: 'custom-table--header' },
        { text: 'Total', value: 'total', align: 'right', class: 'custom-table--header' },
      ]
    },
  },
  created () {},
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Relatório Anual de Utilização de Produtos' },
    ])

    this.initialize()
  },
  methods: {
    initialize () {
      this.ano = this.anoAtual()
    },
    fieldsSelected () {
      this.pendingSearch = true
    },
    anoAtual () {
      return new Date().getFullYear()
    },

    changeTitle () {
      this.title_group = (this.agrupar_por === 'produto' ? 'Produto: ' : 'Produção: ')
    },

    loadData () {
      this.pendingSearch = false

      if (parseInt(this.ano) > 0 && parseInt(this.empresa_id) > 0) {
        this.firstLoading = false
        this.data = []
        this.loading = true
        this.dataError = false

        const params = {
          ano: this.ano,
          empresa_id: this.empresa_id,
          producao_id: this.$array.pluck(this.producao_id, 'id').filter((v) => v !== ''),
          produto_id: this.produto_id,
        }

        ApiService
          .get('/sapiens/produto-utilizacao-anual', '?' + this.$stringFormat.queryParamsRecursive(params))
          .then((res) => {
            this.data = res.data.data
            this.loading = false

            if (this.data.length <= 0) {
              this.dataError = true
            }
          }).catch((error) => {
            this.loading = false
            this.dataError = true
            console.error('Erro: ', error)
          })
      } else {
        this.loading = false
      }
    },

    formatDateHour (dateHour) {
      var d = new Date(dateHour)
      return d.toLocaleString('pt-BR')
    },

    formatNumber (number, decimal) {
      return parseFloat(number).toLocaleString('pt-br', {
        minimumFractionDigits: decimal,
      })
    },

    getDateNow () {
      const now = new Date()
      const dia = ('0' + now.getDate()).slice(-2)
      const mes = ('0' + (now.getMonth() + 1)).slice(-2)
      const ano = now.getFullYear()
      const hora = ('0' + now.getHours()).slice(-2)
      const minuto = ('0' + now.getMinutes()).slice(-2)
      const segundos = ('0' + now.getSeconds()).slice(-2)
      const dataAgora = dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + ':' + segundos
      return dataAgora
    },

    async exportExcel () {
      if (this.data.length > 0) {
        const url = '/relatorios/exportar'
        const filtros = {
          empresa_id: this.empresa_id > 0 ? this.empresa_id : '0',
          producao_id: this.$array.pluck(this.producao_id, 'id').filter((v) => v !== '').toString() ? this.$array.pluck(this.producao_id, 'id').filter((v) => v !== '').toString() : '0',
          produto_id: this.produto_id !== '' ? this.produto_id : '0',
          ano: this.ano,
        }

        const relatorioExcel = this.agrupar_por === 'producao' ? 'UtilizacaoAnualProducoes' : 'UtilizacaoAnualProdutos'
        const params = {
          relatorio: relatorioExcel,
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show('Gerando o arquivo', 'Aguarde alguns segundos para baixar o arquivo', 'success', -1, true)

        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success')
            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          }
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          console.error('Erro: ', error)
        })
      } else {
        this.$refs.snackbar.show('Erro', 'Não foram encontrados dados para exportação!', 'danger')
      }
    },
    async exportPdf () {
      if (this.data.length > 0) {
        this.$refs.snackbar.show(
          'Gerando o arquivo',
          'Aguarde alguns segundos para baixar o arquivo',
          'success',
          -1,
          true,
        )

        let param = `?empresa_id=${this.empresa_id}`
        param += `&producao_id=${this.$array.pluck(this.producao_id, 'id').filter((v) => v !== '').toString() ? this.$array.pluck(this.producao_id, 'id').filter((v) => v !== '').toString() : '0'}`
        param += `&produto_id=${this.produto_id}`
        param += `&agrupar_por=${this.agrupar_por}`
        param += `&ano=${this.ano}`

        ApiService.get('/reports/relatorio-anual', param)
          .then((res) => {
            if (res.status === 200) {
              this.$refs.snackbar.show(
                'Arquivo gerado com sucesso',
                '',
                'success',
              )

              const newWindow = window.open()
              newWindow.document.write(res.data)
            }
          })
          .catch((error) => {
            this.$refs.snackbar.show(
              'Erro',
              'Não foi possível gerar o arquivo, tente novamente mais tarde.',
              'danger',
            )

            console.error('Erro: ', error)
          })
      } else {
        this.$refs.snackbar.show(
          '',
          'Não foram encontrados dados para exportação',
          'alert',
        )
      }
    },
  },
}
</script>
